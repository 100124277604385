/* --- Global --- */
import {Helmet} from 'react-helmet';
import wave from '@assets/images/bump.png';

/* --- Local --- */
import {QuestCard} from '@components';
import questListConsenQuest from '@data/questListConsenQuest';

/* --- Component --- */
const PageHome = props => {
  return (
    <>
      <SearchEngineOptimization />
      <Showcase />
      <Content />
    </>
  );
};

const Showcase = props => {
  return (
    <Atom.Flex
      center
      column
      sx={{
        color: 'white',
        pt: 6,
        pb: 7,
        zIndex: 1000,
      }}>
      <Atom.BackgroundGradient gradient="bluePurple" direction={0} />
      <Atom.Absolute sx={{bottom: 0, left: 0, right: 0}}>
        <Atom.Image src={wave} sx={{height: 130, width: '100%'}} />
      </Atom.Absolute>
      <Atom.Container>
        <Atom.Flex
          center
          co
          sx={{
            flexDirection: ['column', 'column', 'column'],
          }}>
          {/* Left : Region */}
          <Atom.Box sx={{flex: 2, textAlign: 'center'}}>
            <Atom.Span card circle>
              <Atom.Image
                sx={{
                  borderRadius: 9999,
                  width: 100,
                }}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSymm752bT_zWHbWNoZZsHOgrfQslCfjLp7HuCco7dNezKp9K_2NQ&s"
              />
            </Atom.Span>
            <Atom.Heading
              giga
              sx={{
                fontSize: [5, 5, 5, 6],
              }}>
              <Atom.Span sx={{fontWeight: 300}}>ConsenSys Adventures</Atom.Span>
              <br />
              <Atom.Span
                sx={{
                  fontSize: [5, 5, 5],
                }}>
                Get Rewarded to Interact with ConsenSys Products
              </Atom.Span>
            </Atom.Heading>
          </Atom.Box>
        </Atom.Flex>
      </Atom.Container>
    </Atom.Flex>
  );
};

const Content = props => {
  return (
    <Atom.Box>
      <Atom.Container
        sx={{
          pt: 2,
          pb: 4,
        }}>
        <Atom.Flex center column>
          <Atom.Heading as="h5" giga>
            Active Quests
          </Atom.Heading>
          <Atom.Heading as="h5" md normal sx={{color: 'blue'}}>
            Want to earn some ETH? <strong>ConsenSys will hook you up!</strong>
          </Atom.Heading>
        </Atom.Flex>
        <Atom.HorizontalRule
          sx={{
            mt: 4,
            mb: 4,
            width: 50,
            mx: 'auto',
          }}
        />

        <ConsenQuestList />
      </Atom.Container>
    </Atom.Box>
  );
};

const ConsenQuestList = props => {
  return (
    <>
      <Atom.Box>
        <Atom.Flex sx={{flexWrap: 'wrap'}}>
          {questListConsenQuest.map(quest => (
            <QuestCard
              {...quest}
              adventure="consenquest"
              sx={stylesQuestCard}
            />
          ))}
        </Atom.Flex>
      </Atom.Box>
    </>
  );
};

const stylesQuestCard = {
  mx: ['0', '0', '1.5%', '1.5%'],
  mb: 4,
  width: ['100%', '100%', '47%', '30%'],
};

const SearchEngineOptimization = props => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Consenquest - 0.1ETH Reward </title>
      <meta property="og:url" content="http://ethquest.io" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Consenquest - 0.1ETH Reward" />
      <meta
        property="og:description"
        content="Get rewarded to interact with ConsenSys products."
      />
    </Helmet>
  );
};

const WaveBottom = props => {
  return (
    <Atom.Absolute sx={{bottom: -50, left: 0, right: 0, zIndex: 1000}}>
      <Atom.Image
        src={wave}
        sx={{
          transform: 'rotate(180deg)',
          height: 50,
          width: '100%',
        }}
      />
    </Atom.Absolute>
  );
};

export default PageHome;
